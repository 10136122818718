import { Link, useLoaderData, useNavigate } from "react-router-dom";
import Persons from "./index";
import { useGetPerson } from "../../query/person";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import { Tab, Tabs, Typography } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useContext, useMemo, useState } from "react";
import PersonOverview from "./components/PersonOverview";
import PersonConstituency from "./components/PersonConstituency";
import PersonCareer from "./components/PersonCareer";
import Engagements from "./components/Engagements";
import { useParties } from "../../query/search";
import { handlePersonsExport } from "../../services/utils";
import SentEmails from "./components/SentEmails";
import { isFeatureEnabled } from "../../components/utils";
import { Features } from "../../types/User";
import { AuthContext } from "../../context/AuthContext";
import { isFromParlyApi } from "./utils";

const PersonSwitch = () => {
  const { id } = useLoaderData() as { id: string };
  return (
    <>
      {["HoC", "HoL", "NIASSEMBLY", "SCOTPARL", "Senedd"].includes(id) ? (
        <Persons initialFilter={id} />
      ) : (
        <PersonPage id={id} />
      )}
    </>
  );
};

type PersonPageProps = {
  id: string;
};

const PersonPage = ({ id }: PersonPageProps) => {
  const { user } = useContext(AuthContext);
  const { data: person, isLoading } = useGetPerson(id);
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const { data: parties } = useParties();
  const personParty = useMemo(() => {
    if (person && parties)
      return parties.find((party) => party.id === person.party);
  }, [person, parties]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleCreateEmail = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate("/emails/create", {
      state: { personEmails: [person?.email_address] },
    });
  };

  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent={"center"}>
          <Box position="absolute" sx={{ top: "50%" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Box>
        </Box>
      ) : null}
      {person ? (
        <Stack direction={"column"} py={2} px={4} spacing={3}>
          <Link
            to={".."}
            style={{ textDecoration: "auto" }}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          >
            <Button
              variant="outlined"
              color={"primary"}
              size="small"
              startIcon={<ArrowBackIcon />}
            >
              Back to list
            </Button>
          </Link>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Stack direction={"row"} alignItems={"center"} spacing={3}>
              <Avatar src={person?.img_src} sx={{ width: 70, height: 70 }} />
              <Stack direction={"column"}>
                <Typography variant="h1">{person?.name} </Typography>
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  {personParty?.img ? (
                    <Box
                      component="img"
                      src={personParty?.img}
                      sx={{
                        width: "20px",
                        height: "20px",
                      }}
                    ></Box>
                  ) : null}
                  <Typography variant="subtitle2">{person?.party}</Typography>
                  {/* TODO: add time in the party */}
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={"row"} spacing={2}>
              <Button
                variant="contained"
                size="small"
                startIcon={<EmailOutlinedIcon />}
                onClick={handleCreateEmail}
                disabled={!isFeatureEnabled(user, Features.EMAILING)}
              >
                Email
              </Button>
              <Button
                variant="contained"
                size="small"
                startIcon={<FileDownloadOutlinedIcon />}
                onClick={(e) => handlePersonsExport(e, [id])}
              >
                Export
              </Button>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tabs value={tabValue} onChange={handleChange}>
              <Tab label="Overview" value={0} />
              <Tab label="Constituency" value={1} />
              <Tab
                label="Parliamentary Career"
                disabled={isFromParlyApi(person?.organization) ? false : true}
                value={2}
              />
              <Tab label="Activity" disabled={true} value={3} />
              <Tab label="Engagements" value={4} />
              <Tab label="Sent emails" value={5} />
            </Tabs>
          </Stack>

          <>
            {tabValue === 0 && <PersonOverview person={person} />}
            {tabValue === 1 && <PersonConstituency person={person} />}
            {tabValue === 2 && person.parly_uid ? (
              <PersonCareer parly_uid={person.parly_uid} />
            ) : null}
            {tabValue === 4 && <Engagements person={person} />}
            {tabValue === 5 && <SentEmails person={person} />}
          </>
        </Stack>
      ) : null}
    </>
  );
};

export default PersonSwitch;
