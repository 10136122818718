import Box from "@mui/material/Box";

import { ProfileList } from "../types/List";
import { useContext, useMemo, useState } from "react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_TableHeadCellFilterContainer,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Stack from "@mui/material/Stack";
import { Button, Container, InputAdornment, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DateRangeSelect from "./DateRangeSelect";
import { useForm } from "react-hook-form";
import PersonsBadgesWidget from "./MuiTable/PersonsBadgesWidget";

import { useNavigate } from "react-router-dom";
import CreateListModal from "./CreateListModal";
import { usePersonsAll } from "../query/person";
import { isFeatureEnabled } from "./utils";
import { Features } from "../types/User";
import { AuthContext } from "../context/AuthContext";

type ListsTableProps = {
  lists: ProfileList[];
  isFetching: boolean;
};

const ListsTable = ({ lists, isFetching }: ListsTableProps) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { data: persons } = usePersonsAll();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [listsModalOpen, setListsModalOpen] = useState<boolean>(false);
  const { setValue, getValues } = useForm({
    defaultValues: {
      date_from: "",
      date_to: "",
    },
  });
  const handleCreateEmail = (e: React.MouseEvent) => {
    e.preventDefault();
    const selectedLists = lists.filter((list) =>
      Object.keys(rowSelection).includes(list.id),
    );
    const selectedPersonIds = selectedLists
      .map((list) => list.person_ids)
      .flat()
      .filter((id) => id !== undefined);
    const personEmails = persons?.results
      .filter((person) => selectedPersonIds.includes(person.id))
      .map((person) => person.email_address)
      .filter((email) => email !== undefined);
    if (personEmails?.length) {
      navigate("/emails/create", {
        state: { personEmails: personEmails },
      });
    }
  };

  const columns = useMemo<MRT_ColumnDef<ProfileList>[]>(
    () => [
      {
        accessorKey: "list_name",
        header: "Search",
        Cell: ({ row, renderedCellValue }) => (
          <Box
            display="flex"
            flexDirection={"row"}
            alignItems={"center"}
            gap={3}
          >
            <Box
              width={16}
              height={16}
              sx={{
                borderRadius: "4px",
                background: row.original.color,
              }}
            />
            {renderedCellValue}
          </Box>
        ),
        muiFilterTextFieldProps: {
          id: "outlined-basic",
          placeholder: "Search",
          name: "search",
          size: "small",
          variant: "outlined",
          sx: {
            flex: 1,
            minWidth: "180px",
          },
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          },
        },
      },
      {
        accessorKey: "created_at",
        header: "Date created",
        size: 80,
        accessorFn: (row) =>
          row?.created_at
            ? new Date(row?.created_at as string).toLocaleDateString()
            : "N/A",
        Filter: ({ column }) => (
          <DateRangeSelect
            setValue={(key: any, value: string) => {
              setValue(key, value);
              column.setFilterValue(
                `${getValues("date_from")}_${getValues("date_to")}`,
              );
            }}
          />
        ),
        filterFn: (row, _columnIds, filterValue) => {
          const [date_from, date_to] = filterValue.split("_");
          if (!date_from || !date_to) return true;
          return (
            new Date(row.getValue<string>("created_at")) < new Date(date_to) &&
            new Date(row.getValue<string>("created_at")) > new Date(date_from)
          );
        },
      },
      {
        accessorKey: "category",
        header: "Category",
        size: 80,
        accessorFn: (row) => row?.category || "N/A",
        muiFilterTextFieldProps: {
          id: "outlined-basic",
          label: "Category",
          name: "category",
          size: "small",
          variant: "outlined",
        },
      },
      {
        header: "People",
        enableColumnFilter: false,
        enableColumnFilterModes: false,
        showColumnFilters: false,
        Cell: ({ row }) =>
          row.original.person_ids && row.original.person_ids.length ? (
            <PersonsBadgesWidget ids={row.original.person_ids} />
          ) : null,
        Filter: () => null,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetching, getValues, setValue],
  );
  const table = useMaterialReactTable({
    columns,
    data: lists,
    columnFilterDisplayMode: "custom",
    enableTopToolbar: false,
    enableColumnActions: false,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    getRowId: (originalRow) => originalRow.id,
    selectAllMode: "all",
    muiTablePaperProps: {
      elevation: 0,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 25, 50],
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        event.preventDefault();
        navigate(`/lists/${row.original.id}`);
      },
      sx: {
        cursor: "pointer",
      },
    }),
  });
  return (
    <Box mb={10}>
      <Stack
        direction={"row"}
        py={4}
        borderBottom={1}
        borderColor={"divider"}
        px={8}
        justifyContent={"space-between"}
      >
        <Stack direction="column" gap={2}>
          <Typography variant="h1">My lists</Typography>

          <Typography variant="subtitle1">
            Look up biographies and contact details, and manage your
            relationship with people.
          </Typography>
        </Stack>
      </Stack>
      <Container maxWidth="xl">
        <Stack direction={"row"} justifyContent={"space-between"} py={2.5}>
          <Stack direction={"row"} gap={2.5}>
            {table.getLeafHeaders().map((header) => {
              return ["list_name", "created_at", "category"].includes(
                header.id,
              ) ? (
                <MRT_TableHeadCellFilterContainer
                  key={header.id}
                  header={header}
                  table={table}
                  in
                />
              ) : null;
            })}
          </Stack>
          <Stack direction={"row"} gap={2.5}>
            <Button
              variant="contained"
              color="primary"
              disabled={
                Object.keys(rowSelection).length === 0 ||
                !isFeatureEnabled(user, Features.EMAILING)
              }
              onClick={handleCreateEmail}
            >
              Send Emails
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setListsModalOpen(true)}
            >
              Create new list
            </Button>
          </Stack>
        </Stack>
        <MaterialReactTable table={table} />
      </Container>
      <CreateListModal
        open={listsModalOpen}
        toggle={() => setListsModalOpen(!listsModalOpen)}
      />
    </Box>
  );
};

export default ListsTable;
