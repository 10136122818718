import { DrawerStateFilterType, OrganisationsFilterItem } from ".";

interface Entity {
  type: string;
  name: string;
  id: string;
  parties?: EntityGroup;
  committees?: EntityGroup;
  cross_party_groups?: EntityGroup;
  opened?: boolean;
}

interface EntityGroup {
  type: string;
  name: string;
  id: string;
  opened?: boolean;
  data?: Entity[];
}

const replaceUnderscoresWithDots = (drawerState: Record<string, boolean>) => {
  const updatedDrawerState: Record<string, boolean> = {};
  for (const key in drawerState) {
    if (drawerState.hasOwnProperty(key)) {
      const newKey = key.replace(/_/g, ".");
      updatedDrawerState[newKey] = drawerState[key];
    }
  }
  return updatedDrawerState;
};

export const replaceDotsWithUnderscores = (id: string) => {
  return id.replace(/\./g, "_");
};

export const updateFilter = (
  drawerState: Record<string, boolean>,
  organisationsFilterData: OrganisationsFilterItem[],
) => {
  const updatedDrawerState = replaceUnderscoresWithDots(drawerState);
  const filterState: DrawerStateFilterType = {
    houses: [],
    committees: [],
    crossPartyGroups: [],
    parties: [],
    specialAdvisers: [],
  };

  const onlyChecked = Object.fromEntries(
    Object.entries(updatedDrawerState).filter(([key, value]) => value === true),
  );

  for (let i = 0; i < organisationsFilterData.length; i++) {
    if (onlyChecked[organisationsFilterData[i].id]) {
      filterState.houses.push(organisationsFilterData[i].id);
    }
    // TODO: account for all appgs and cttees for a house
    if (organisationsFilterData[i].cttees) {
      for (let j = 0; j < organisationsFilterData[i].cttees.length; j++) {
        if (onlyChecked[organisationsFilterData[i].cttees[j].id]) {
          filterState.committees.push(organisationsFilterData[i].cttees[j].id);
          filterState.houses.push(organisationsFilterData[i].id);
        }
      }
    }
    if (organisationsFilterData[i].appgs) {
      for (let j = 0; j < organisationsFilterData[i].appgs.length; j++) {
        if (onlyChecked[organisationsFilterData[i].appgs[j].id]) {
          filterState.crossPartyGroups.push(
            organisationsFilterData[i].appgs[j].id,
          );
          filterState.houses.push(organisationsFilterData[i].id);
        }
      }
    }
    if (organisationsFilterData[i].parties) {
      for (let j = 0; j < organisationsFilterData[i].parties.length; j++) {
        if (onlyChecked[organisationsFilterData[i].parties[j].id]) {
          filterState.parties.push(organisationsFilterData[i].parties[j].id);
          filterState.houses.push(organisationsFilterData[i].id);
        }
      }
    }
    if (organisationsFilterData[i].specialAdvisers) {
      for (
        let j = 0;
        j < organisationsFilterData[i].specialAdvisers.length;
        j++
      ) {
        if (onlyChecked[organisationsFilterData[i].specialAdvisers[j].id]) {
          filterState.specialAdvisers.push(
            organisationsFilterData[i].specialAdvisers[j].id,
          );
          filterState.houses.push(organisationsFilterData[i].id);
        }
      }
    }
  }

  filterState.houses = filterState.houses.filter(
    (value, index, self) => self.indexOf(value) === index,
  );
  filterState.committees = filterState.committees.filter(
    (value, index, self) => self.indexOf(value) === index,
  );
  filterState.crossPartyGroups = filterState.crossPartyGroups.filter(
    (value, index, self) => self.indexOf(value) === index,
  );
  filterState.parties = filterState.parties.filter(
    (value, index, self) => self.indexOf(value) === index,
  );
  filterState.specialAdvisers = filterState.specialAdvisers.filter(
    (value, index, self) => self.indexOf(value) === index,
  );
  return filterState;
};

export const getOrgNameFromKey = (org: string) => {
  if (org === "HoC") {
    return "House of Commons";
  }
  if (org === "HoL") {
    return "House of Lords";
  }
  if (org === "SPADS") {
    return "Special Adviser";
  }
  if (org === "NIASSEMBLY") {
    return "Northern Ireland Assembly";
  }
  if (org === "SCOTPARL") {
    return "Scottish Parliament";
  }
  if (org === "Senedd") {
    return "Senedd";
  } else {
    return "N/A";
  }
};

export const isFromParlyApi = (org?: string) => {
  return org ? ["HoC", "HoL"].includes(org) : false;
};
