import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import {
  useCreateEmail,
  useGetEmail,
  useSendEmail,
  useUpdateEmail,
} from "../../query/emails";
import { EmailType } from "../../types/Email";
import EmailsAutocompleteField from "./EmailsAutocompleteField";
import { usePersonsAll } from "../../query/person";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import { useForm } from "react-hook-form";
import AttachmentLinkWidget from "../../components/AttachmentsLinkWidget";
import FileInputWithModal from "../../components/FileInput";
import Button from "@mui/material/Button";
import { useMemo, useState } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import { useGetUsers } from "../../query/user";
import HookedMultiSelect from "../../components/HookedMultiSelect";
import HookedFormSelectField from "../../components/HookedSelectField";
import MenuItem from "@mui/material/MenuItem";
import CKEditorComponent from "../../components/CKEditorComponent";
import { CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import EmailPreviewModal from "./components/EmailPreviewModal";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useDeleteAttachmentMutation } from "../../query/attachment";

type EmailFormUpdateProps = {
  emailId: string;
};

const EmailFormCreate = () => {
  const { state } = useLocation();
  const personsEmails = state?.personEmails;
  return (
    <>
      <EmailForm personsEmails={personsEmails} />
    </>
  );
};

const EmailFormUpdate = ({ emailId }: EmailFormUpdateProps) => {
  const { data: email } = useGetEmail(emailId);
  const { data: persons, isLoading } = usePersonsAll();
  const existingEmails = useMemo<string[] | undefined>(() => {
    if (email?.recipients && persons) {
      const emailsList: string[] = [];
      email.recipients.forEach((recipient) => {
        const person = persons.results.find(
          (person) => person.id === recipient,
        );
        if (person?.email_address) {
          emailsList.push(person.email_address);
        }
      });
      return Array.from(new Set(emailsList));
    }
  }, [persons, email?.recipients]);
  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent={"center"}>
          <Box position="absolute" sx={{ top: "50%" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Box>
        </Box>
      ) : email && existingEmails ? (
        <EmailForm email={email} personsEmails={existingEmails} />
      ) : null}
    </>
  );
};

type EmailFormProps = {
  email?: EmailType;
  personsEmails: string[];
};

export type EmailFormType = {
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
  subject: string;
  content: string;
  from_field: string;
  replyTo: string[];
  org_id_str: string;
  org_id: string;
  attachments: string[];
  sentDraft: string;
  id: string;
  sent: string;
  recipients: string[];
  test_email?: string;
};

const EmailForm = ({ email, personsEmails }: EmailFormProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("xl"));
  const { data: persons } = usePersonsAll();

  const { mutate: create } = useCreateEmail();
  const { mutate: update } = useUpdateEmail();
  const { mutate: send, isPending } = useSendEmail();
  const { data: users } = useGetUsers();
  const [previewOpen, setPreviewOpen] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    formState,
    reset,
  } = useForm<EmailFormType>({
    defaultValues: {
      subject: email ? email.subject : "",
      content: email ? email.content : "",
      attachments: email ? (email.files as string[]) : [],
      from_field: email ? email.from_field : "",
      replyTo: email ? email.replyTo : [],
      test_email: "",
      recipients: personsEmails ? personsEmails : [],
    },
  });

  const personEmails = Array.from(
    new Set(
      persons?.results
        .map((person) => person.email_address)
        .filter((email) => email),
    ),
  );

  const getPersonIds = (emails: string[]) => {
    return persons?.results
      .filter((person) => emails.includes(person.email_address))
      .map((person) => person.id);
  };

  const getUserEmailsByIds = (ids: string[]) => {
    return users?.results
      .filter((user) => ids.includes(user.id))
      .map((user) => user.email);
  };

  const getRemovedAttachments = (attachments: string[]) => {
    return email?.files?.filter((file) => !attachments.includes(file));
  };

  const { mutate: deleteAttachmentsMutation } = useDeleteAttachmentMutation();

  const deleteAttachments = (attachmentsToDelete: string[]) => {
    if (attachmentsToDelete) {
      attachmentsToDelete.forEach((attachment) => {
        deleteAttachmentsMutation(attachment);
      });
    }
  };

  const submitForm = () => {
    const formValues = getValues();
    if (email) {
      update(
        {
          emailId: email.id,
          data: {
            ...formValues,
            recipients: getPersonIds(formValues.recipients),
            files: formValues.attachments,
            replyTo: getUserEmailsByIds(formValues.replyTo),
          },
        },
        {
          onSuccess: () => {
            const attachmentsToDelete = getRemovedAttachments(
              formValues.attachments,
            );
            if (attachmentsToDelete) {
              deleteAttachments(attachmentsToDelete);
            }
            reset(formValues);
          },
        },
      );
    } else {
      create(
        {
          ...formValues,
          recipients: getPersonIds(formValues.recipients),
          files: formValues.attachments,
          replyTo: getUserEmailsByIds(formValues.replyTo),
        },
        {
          onSuccess: (result) => {
            navigate(`/emails/${result.id}`);
          },
        },
      );
    }
  };

  const handleSendEmail = (
    e: React.MouseEvent<HTMLButtonElement>,
    test?: boolean,
  ) => {
    e.preventDefault();
    const formValues = getValues();
    if (email) {
      update(
        {
          emailId: email.id,
          data: {
            ...formValues,
            recipients: getPersonIds(formValues.recipients),
            files: formValues.attachments,
            replyTo: getUserEmailsByIds(formValues.replyTo),
          },
        },
        {
          onSuccess: () => {
            send({
              emailId: email.id,
              test,
              test_email: formValues.test_email,
            });
            const attachmentsToDelete = getRemovedAttachments(
              formValues.attachments,
            );
            if (attachmentsToDelete) {
              deleteAttachments(attachmentsToDelete);
            }
            reset(formValues);
          },
        },
      );
    } else {
      create(
        {
          ...formValues,
          recipients: getPersonIds(formValues.recipients),
          files: formValues.attachments,
          replyTo: getUserEmailsByIds(formValues.replyTo),
        },
        {
          onSuccess: (result) => {
            send({
              emailId: result.id,
              test,
              test_email: formValues.test_email,
            });
            navigate(`/emails/${result.id}`);
          },
        },
      );
    }
  };

  const handleDeleteAttachment = (attachmentId: string) => {
    setValue(
      "attachments",
      getValues("attachments").filter((id) => id !== attachmentId),
      { shouldDirty: true },
    );
  };

  return (
    <Container maxWidth="xl">
      {personEmails && personEmails.length > 0 ? (
        <>
          <Stack
            direction={"row"}
            py={4}
            borderBottom={1}
            borderColor={"divider"}
            pr={8}
            pl={0}
            justifyContent={"space-between"}
            display={"flex"}
          >
            <Typography variant="h1">Emails</Typography>
            <Button
              variant="contained"
              size="small"
              startIcon={<ArrowBackIosNewIcon />}
              onClick={() => navigate("/emails")}
            >
              Back to emails
            </Button>
          </Stack>
          <form onSubmit={handleSubmit(submitForm)}>
            <Stack py={4} spacing={2}>
              <EmailsAutocompleteField
                data={personEmails}
                addedEmails={watch("recipients")}
                status={email?.status || "draft"}
                setEmails={setValue}
              />
              <Stack direction={"row"} spacing={2}>
                <TextField
                  {...register("subject", { required: true })}
                  id="outlined-basic"
                  label="Subject:"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={email?.status === "sent"}
                />
                <HookedFormSelectField
                  control={control}
                  label="From:"
                  name="from_field"
                  required
                  select
                  fullWidth
                  size="small"
                  disabled={email?.status === "sent"}
                >
                  {users?.results
                    .filter((user) => user.email && user.name)
                    .map((user) => (
                      <MenuItem value={user.email} key={user.id}>
                        {user.name} {user.email}
                      </MenuItem>
                    ))}
                </HookedFormSelectField>
              </Stack>

              <Stack direction={"row"} spacing={2}>
                <HookedMultiSelect
                  fieldName="replyTo"
                  control={control}
                  labelName="Reply to:"
                  data={users?.results.filter(
                    (user) => user.email && user.name,
                  )}
                  selection={watch("replyTo")}
                  itemLabelName="email"
                  fullWidth
                  disabled={email?.status === "sent"}
                />
                <TextField
                  {...register("test_email")}
                  id="outlined-basic"
                  label="Send test email to:"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={email?.status === "sent"}
                />
              </Stack>

              <Stack direction={"column"} spacing={1}>
                <Box display="inline-flex">
                  <Typography variant="h4">Email Content</Typography>
                  <Tooltip title="Allowed templates for email content and subject: {Salutation}, {First Name}, {Last Name}">
                    <HelpOutlineIcon
                      sx={{
                        marginLeft: 0.3,
                        color: "grey",
                        fontSize: "small",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Box>

                <Box
                  sx={{
                    ".ck-editor__editable": {
                      height: matches ? "600px" : "400px",
                    },
                  }}
                >
                  <CKEditorComponent
                    data={email ? `${watch("content")}` : ""}
                    disabled={email?.status === "sent"}
                    onReady={register}
                    onChange={setValue}
                  />
                </Box>
              </Stack>
              {watch("attachments") ? (
                <Box>
                  {watch("attachments").map((att, ind) => {
                    return (
                      <AttachmentLinkWidget
                        key={ind}
                        attachmentId={att}
                        onDelete={() => handleDeleteAttachment(att)}
                      />
                    );
                  })}
                </Box>
              ) : null}
              {email?.status === "sent" ? null : (
                <Stack direction={"column"} spacing={1}>
                  <Typography variant="h4">Select file to upload</Typography>
                  <FileInputWithModal
                    register={register}
                    getValues={getValues}
                    setValue={setValue}
                  />
                </Stack>
              )}

              <Box
                display={"flex"}
                justifyContent={"space-between"}
                py={2}
                px={2}
                sx={{
                  borderTop: 1,
                  borderColor: "divider",
                }}
              >
                <Box display={"flex"} gap={1}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={(e) => handleSendEmail(e, true)}
                    disabled={email?.status === "sent" || !watch("test_email")}
                  >
                    Test send
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setPreviewOpen(!previewOpen)}
                    disabled={
                      !email?.recipients ||
                      email?.recipients.length === 0 ||
                      persons?.results?.length === 0
                    }
                  >
                    Preview
                  </Button>
                </Box>
                <Box display={"flex"} gap={1}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => navigate("/emails")}
                  >
                    Cancel{" "}
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    type="submit"
                    disabled={email?.status === "sent" || !formState.isDirty}
                  >
                    Save draft
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      backgroundColor: "red",
                    }}
                    onClick={handleSendEmail}
                    disabled={
                      email?.status === "sent" || !watch("recipients").length
                    }
                  >
                    {isPending ? <CircularProgress size={20} /> : "Send"}
                  </Button>
                </Box>
              </Box>
            </Stack>
          </form>
          {previewOpen && email?.recipients && persons?.results ? (
            <EmailPreviewModal
              email={email}
              persons={persons?.results}
              personId={email?.recipients?.[0]}
              open={previewOpen}
              setOpen={setPreviewOpen}
            />
          ) : null}
        </>
      ) : null}
    </Container>
  );
};

const EmailSwitch = () => {
  const { id } = useLoaderData() as { id: string };

  return (
    <>
      {id === "create" ? <EmailFormCreate /> : <EmailFormUpdate emailId={id} />}
    </>
  );
};

export default EmailSwitch;
