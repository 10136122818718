import Box from "@mui/material/Box";
import { useListsAll } from "../../query/list";
import { useGetContactsAll } from "../../query/person";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { useContext, useEffect, useMemo, useState } from "react";
import AddOrCreateListModal from "../../components/AddOrCreateListModal";
import PersonCreateModal from "./components/PersonCreateModal";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";

import {
  MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_RowSelectionState,
  MRT_TableHeadCellFilterContainer,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Person } from "../../types/Person";
import Avatar from "@mui/material/Avatar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Grid, MenuItem, Select, useTheme } from "@mui/material";
import ListsBadgesWidget from "../../components/MuiTable/ListsBadgesWidget";
import PersonBioDetail from "../../components/MuiTable/PersonBioDetail";
import { useNavigate } from "react-router-dom";
import { getInitialsFromName } from "../../components/MuiTable/utils";
import { handlePersonsExport } from "../../services/utils";
import { NoteBulkCreateModalContainer } from "../Engagements/NotesModals";
import { isFeatureEnabled } from "../../components/utils";
import { Features } from "../../types/User";
import { AuthContext } from "../../context/AuthContext";

const ContactsPage = () => {
  const theme = useTheme();
  const {
    data: lists,
    isLoading: listsLoading,
    isFetching: listsFetching,
  } = useListsAll();
  const { data: persons, isLoading: personsLoading } = useGetContactsAll();
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);

  const matches = useMediaQuery(theme.breakpoints.up("xl"));

  const getCellWidth = (matches: boolean) => {
    if (matches) {
      return 200;
    } else {
      return 80;
    }
  };

  const columns = useMemo<MRT_ColumnDef<Person>[]>(
    () => [
      {
        accessorFn: (row) => `${row.first_name} ${row.surname}`,
        header: "Profile",
        Cell: ({ renderedCellValue }) => (
          <Stack direction="row" alignItems={"center"} gap={2}>
            <Avatar>
              {renderedCellValue
                ? getInitialsFromName(renderedCellValue.toString() as string)
                : null}
            </Avatar>
            {renderedCellValue}
          </Stack>
        ),
      },
      {
        accessorKey: "organization",
        header: "Organization",
        filterVariant: "multi-select",
        size: getCellWidth(matches),
        muiFilterTextFieldProps: {
          id: "outlined-basic",
          name: "organization",
          size: "small",
          variant: "outlined",
          SelectProps: {
            sx: { color: (theme) => theme.palette.grey[600] },
            renderValue: (selected: any) => {
              return selected.length
                ? `${selected.length} selected`
                : "Organization";
            },
          },
        },
      },
      {
        accessorKey: "department",
        header: "Department",
        filterVariant: "multi-select",
        size: getCellWidth(matches),
        muiFilterTextFieldProps: {
          id: "outlined-basic",
          name: "department",
          size: "small",
          variant: "outlined",

          SelectProps: {
            sx: { color: (theme) => theme.palette.grey[600], pr: 1 },
            renderValue: (selected: any) => {
              return selected.length
                ? `${selected.length} selected`
                : "Department";
            },
          },
        },
      },
      {
        header: "Current role",
        accessorKey: "current_roles_titles",
        size: getCellWidth(matches),
      },
      {
        accessorFn: (row) => (row.gender === "M" ? "Male" : "Female"),
        header: "Gender",
        size: getCellWidth(matches),
        filterVariant: "select",
        filterSelectOptions: [
          { value: "Male", label: "Male" },
          { value: "Female", label: "Female" },
        ],
        muiFilterTextFieldProps: {
          id: "outlined-basic",
          name: "gender",
          size: "small",
          variant: "outlined",
          SelectProps: {
            sx: { color: (theme) => theme.palette.grey[600], pr: 1 },
            renderValue: (selected: any) => {
              return selected ? `${selected}` : "Gender";
            },
          },
        },
      },
      {
        //TODO: add color coding via renderColumnFilterModeMenuItems
        filterVariant: "multi-select",
        accessorKey: "lists",
        size: getCellWidth(matches),
        accessorFn: (row) => {
          const filtered = lists?.results?.filter((list) =>
            list.person_ids?.includes(row.id),
          );
          return filtered ? filtered.map((list) => list.list_name) : [];
        },
        filterSelectOptions: lists?.results?.map((list) => list.list_name),
        header: "Lists",

        Cell: ({ row }) => <ListsBadgesWidget personId={row.original?.id} />,
        muiFilterTextFieldProps: {
          id: "outlined-basic",
          name: "lists",
          size: "small",
          variant: "outlined",
          SelectProps: {
            sx: { color: (theme) => theme.palette.grey[600] },
            renderValue: (selected: any) => {
              return selected.length ? `${selected.length} selected` : "Lists";
            },
          },
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listsFetching, lists, matches],
  );

  return (
    <>
      {personsLoading || listsLoading ? (
        <Box display="flex" justifyContent={"center"}>
          <Box position="absolute" sx={{ top: "50%" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Box>
        </Box>
      ) : null}
      {persons?.results && lists?.results ? (
        <ContactsContainer
          persons={persons.results}
          columns={columns}
          selected={selected}
          setSelected={setSelected}
        />
      ) : null}
      {persons && !persons.results.length && !listsLoading ? (
        <Box display="flex" justifyContent={"center"}>
          <Box textAlign={"center"} pt={10} sx={{ top: "50%" }}>
            <Stack direction={"column"} spacing={2} alignItems={"center"}>
              <Typography variant="h2">Add new contacts</Typography>
              <Typography variant="h3">
                You can add new contacts to your CRM
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setCreateModalOpen(true)}
                sx={{ maxWidth: 180 }}
                size="small"
              >
                Create new contacts
              </Button>
            </Stack>
          </Box>
        </Box>
      ) : null}
      <PersonCreateModal open={createModalOpen} setOpen={setCreateModalOpen} />
    </>
  );
};

type ContactsContainerProps = {
  persons: Person[];
  columns: MRT_ColumnDef<Person>[];
  selected: string[];
  setSelected: (selected: string[]) => void;
};

const ContactsContainer = ({
  persons,
  columns,
  selected,
  setSelected,
}: ContactsContainerProps) => {
  const { user } = useContext(AuthContext);
  const [listsModalOpen, setListsModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const navigate = useNavigate();
  const [action, setAction] = useState<number>(1);
  const [notesOpen, setNotesOpen] = useState<boolean>(false);

  const table = useMaterialReactTable({
    columns,
    data: persons,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableRowSelection: true,
    enableFacetedValues: true,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    selectAllMode: "all",
    muiTablePaperProps: {
      elevation: 0,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 25, 50],
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        event.preventDefault();
        navigate(`/people/${row.original.id}`);
      },
      sx: {
        cursor: "pointer",
      },
    }),
    initialState: {
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-expand"],
      },
      showGlobalFilter: true,
    },
    enableExpandAll: false,
    renderDetailPanel: ({ row }) =>
      row.original.id ? <PersonBioDetail personId={row.original.id} /> : null,
  });
  const getSelectedEmails = (selected: string[], persons?: Person[]) => {
    if (!persons) return [];
    return persons
      .filter((person) => selected.includes(person.id))
      .map((person) => person.email_address);
  };

  useEffect(() => {
    if (Object.keys(rowSelection).length !== 0) {
      setSelected(
        table.getSelectedRowModel().rows.map((row) => row.original.id),
      );
    }
  }, [setSelected, rowSelection, table]);

  useEffect(() => {
    if (selected.length === 0) {
      setRowSelection({});
    }
  }, [selected, setRowSelection]);

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    switch (action) {
      case 1:
        handlePersonsExport(e, selected);
        break;
      case 2:
        setNotesOpen(true);
        break;
      case 3:
        navigate("/emails/create", {
          state: { personEmails: getSelectedEmails(selected, persons) },
        });
        break;
      case 4:
        setListsModalOpen(true);
        break;
    }
  };

  return (
    <Container maxWidth="xl">
      {persons?.length ? (
        <Stack direction="column">
          <Stack
            direction={"row"}
            py={4}
            borderBottom={1}
            borderColor={"divider"}
            alignItems={"center"}
            pr={4}
            minHeight={120}
            justifyContent={"space-between"}
          >
            <Typography variant="h1">Contacts</Typography>

            <Stack direction="row" gap={3} alignItems={"center"}>
              <Typography variant="h3">Actions:</Typography>
              <Select
                defaultValue={1}
                onChange={(e) => setAction(e.target.value as number)}
                disabled={selected.length === 0}
                sx={{
                  minWidth: 280,
                }}
                size="small"
              >
                <MenuItem value={1}>
                  Export data for {selected.length} selected
                </MenuItem>
                <MenuItem value={2}>
                  Add a note for {selected.length} selected
                </MenuItem>
                <MenuItem
                  value={3}
                  disabled={!isFeatureEnabled(user, Features.EMAILING)}
                >
                  Email to {selected.length} selected
                </MenuItem>
                <MenuItem value={4}>Add {selected.length} to a List</MenuItem>
              </Select>
              <Button
                variant="contained"
                disabled={selected.length === 0}
                onClick={handleConfirm}
              >
                Confirm selection
              </Button>
              <Button
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={() => setCreateModalOpen(true)}
              >
                Create new contact
              </Button>
            </Stack>
          </Stack>

          <Container maxWidth="xl">
            <Grid container display={"inline-flex"} alignItems={"center"}>
              <Grid item md={3} mr={1}>
                <MRT_GlobalFilterTextField table={table} />
              </Grid>
              <Grid container py={3} spacing={2} md={9}>
                {table.getLeafHeaders().map((header) => {
                  return ["gender", "region", "lists", "organization"].includes(
                    header.id,
                  ) ? (
                    <Grid item md={3}>
                      <MRT_TableHeadCellFilterContainer
                        key={header.id}
                        header={header}
                        table={table}
                        in
                      />
                    </Grid>
                  ) : null;
                })}
              </Grid>
            </Grid>

            <MaterialReactTable table={table} />
          </Container>
        </Stack>
      ) : null}

      <AddOrCreateListModal
        people={selected}
        setSelected={setSelected}
        open={listsModalOpen}
        toggle={() => setListsModalOpen(!listsModalOpen)}
      />
      <NoteBulkCreateModalContainer
        personIds={selected}
        open={notesOpen}
        setOpen={setNotesOpen}
        setSelected={setSelected}
      />
      <PersonCreateModal open={createModalOpen} setOpen={setCreateModalOpen} />
    </Container>
  );
};

export default ContactsPage;
